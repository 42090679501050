import Image from "next/image";
import withWindowDimensions from "components/common/withWindowDimensions";
import { LoginProps, withLogin } from "components/Login/withLogin";
import { useSelector } from "react-redux";
import { AuthToken } from "lib/auth_token";
import { useRef } from "react";
import { trackClick } from "scripts/segment";
import { pageName } from "utils/events-utils";

interface Props {
  img: string;
  title: string;
  content: string;
  gradientColors: any;
  downloadUrl: string;
  isMobileSized?: boolean;
  isButtonVisible?: boolean;
  cardHeight?: string;
  cardWidth?: string;
}

const GuideCard = ({
  img,
  title,
  content,
  gradientColors,
  isMobileSized,
  isButtonVisible,
  cardHeight,
  cardWidth,
  initLogin,
  downloadUrl,
}: Props & LoginProps) => {
  const authValue = useSelector((state: any) => state?.auth?.auth?.token);
  const authToken = new AuthToken(authValue);
  const isLoggedIn = authToken && authToken.isValid;
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <div
      className={`h-full bg-gradient-to-b ${gradientColors.from} ${
        gradientColors.to
      } ${
        cardWidth || "w-60"
      } md:w-80 h-1/3 rounded-lg m-auto md:m-0 mt-20 p-5 mb-4 ${
        cardHeight || "md:min-h-[452px]"
      } flex flex-col justify-between`}
    >
      <div>
        <div className="max-w-[50%] h-auto">
          <Image src={img} alt="guide_img" width={170} height={170} />
        </div>
        <p className={`font-bold text-lg md:text-2xl text-gray-2350 mt-4 ml-4`}>
          {title}
        </p>
        <p className="font-normal text-sm md:text-lg text-gray-2350 ml-4 mt-5">
          {content}
        </p>
      </div>
      {isButtonVisible && (
        <div className="w-full inline-block text-center mt-6">
          <button
            onClick={() => {
              trackClick(pageName.LeapScholarHomePage, {
                contentName: "Download Now",
                widgetName: title,
                widgetFormat: "Banner",
                contentFormat: "Button",
              });

              if (isLoggedIn) {
                downloadLinkRef?.current?.click();
              } else {
                initLogin(
                  "Login to Download Guide",
                  true,
                  "",
                  "counselling",
                  () => {
                    // download given pdf
                    downloadLinkRef?.current?.click();
                  }
                );
              }
            }}
            className="w-11/12 bg-white md:text-font16 text-font14 font-bold text-primary py-3 px-6 rounded border-2 border-primary cursor-pointer"
          >
            <span className="md:flex md:justify-between">
              Download Guide
              {!isMobileSized && (
                <Image
                  src= {`${process.env.CDN_URL}/assets/icons/LSRevamp/GuideSectionLandingPage/download_now.svg`}
                  alt="download now"
                  width={15}
                  height={15}
                />
              )}
            </span>
          </button>
          <a
            href={downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            hidden
            download
            ref={downloadLinkRef}
          />
        </div>
      )}
    </div>
  );
};

export default withWindowDimensions(withLogin(GuideCard));
